import { Link, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Faq from "../components/faq/faq";
import Footer from "../components/footer/footer";
import Header from "../components/header/header";
import News from "../components/news/news";
import ContentWithImage from "../components/products/contentWithImage/contentWithImage";
import Hero from "../components/products/hero/hero";
import Percent from "../components/products/percent/percent";
import Seo from "../components/seo";
import DemoButton from './../components/demoButton/demoButton';
import Testimonial from "../components/products/testimonial/testimonial";
import Partners from "../components/products/partners/partners";
import ContactForm from "../components/products/contactForm/contactForm";
import Percents from './../components/products/percents/percents';


// markup
const GuestFeedbackMonitoringPage = ({ location, data }) => {
  const formRef = React.useRef(null);
  const scrollToForm = () => {
    formRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const excerpt = ``;

  const heroBlock = {
    title: <>Take Control of<br /> Your Restaurant's <span style={{ backgroundImage: 'linear-gradient(90deg, #FE6A01 53.2%, #B32E01 85.55%)' }}>reputation.</span></>,
    desktop: {
      image: (
        <StaticImage
          placeholder="none"
          loading="eager"
          quality="100"
          alt="guest-feedback-monitoring"
          src="../images/guest-feedback-monitoring/hero.png"
          className="mw-100"
        />
      ),
      smallImageStyle: {
        bottom: '40%',
        right: '25%',
        maxWidth: '405px',
      },
      smallImage: (
        <StaticImage
          placeholder="none"
          loading="eager"
          quality="100"
          alt="guest-feedback-monitoring"
          src="../images/guest-feedback-monitoring/hero-floating.png"
          className="mw-100"
        />
      )
    },
    mobile: {
      image: (
        <StaticImage
          placeholder="none"
          loading="eager"
          quality="100"
          alt="guest-feedback-monitoring"
          src="../images/guest-feedback-monitoring/hero.png"
          className="mw-100"
        />
      ),
      smallImageStyle: {},
      smallImage: (
        <StaticImage
          placeholder="none"
          loading="eager"
          quality="100"
          alt="guest-feedback-monitoring"
          src="../images/guest-feedback-monitoring/hero-floating.png"
          style={{ maxWidth: '300px' }}
        />
      )
    },
    description: <></>,
  }

  const localListings = {
    reverse: false,
    badge: false,
    title: <><span style={{ backgroundImage: 'linear-gradient(90deg, #FF6C02 0.1%, #D84C02 62.75%)' }}>Guest Feedback<br /> Monitoring</span></>,
    description: <>Build customer trust and outpace the<br /> competition with a robust guest feedback<br /> program.</>,
    button: <DemoButton scrollToForm={scrollToForm} classes={[]}>Schedule a DEMO</DemoButton>,
    itemsTitle: <>3 Sources of Feedback</>,
    items: [
      {
        type: 'withIcon',
        icon: <StaticImage
          placeholder="none"
          loading="eager"
          quality="100"
          alt="in-site-review"
          src="../images/guest-feedback-monitoring/in-site-review.png"
          className="mw-100"
        />,
        title: <>Order<br /> Placed</>,
        description: <>In-Site business<br /> Review</>,
      },
      {
        type: 'withIcon',
        icon: <StaticImage
          placeholder="none"
          loading="eager"
          quality="100"
          alt="emailed-product"
          src="../images/guest-feedback-monitoring/emailed-product.png"
          className="mw-100"
        />,
        title: <>Order<br /> Complete</>,
        description: <>Emailed product<br /> Review</>,
      },
      {
        type: 'withIcon',
        icon: <StaticImage
          placeholder="none"
          loading="eager"
          quality="100"
          alt="mainstream-platforms"
          src="../images/guest-feedback-monitoring/mainstream-platforms.png"
          className="mw-100"
        />,
        title: <>Experience<br /> Review</>,
        description: <>Emailed product<br /> review. Pushed to<br /> mainstream<br /> platforms</>,
      },
    ],
    addAfterItems: false,
    image: {
      desktop: (
        <StaticImage
          placeholder="none"
          loading="eager"
          quality="100"
          alt="guest-feedback-monitoring"
          src="../images/guest-feedback-monitoring/guest-feedback-monitoring.png"
          className="mw-100"
        />
      ),
      mobile: (
        <StaticImage
          placeholder="none"
          loading="eager"
          quality="100"
          alt="guest-feedback-monitoring"
          src="../images/guest-feedback-monitoring/guest-feedback-monitoring.png"
          className="mw-100"
        />
      ),
    },
  }

  const percent1Block = {
    vertical: false,
    percent: <><span style={{ backgroundImage: 'linear-gradient(125deg, #27FFFF 29.34%, #000 144.39%)' }}>77%</span></>,
    description: <>Of diners rely on peer reviews over critic reviews</>
  }

  const testimonial = {
    title: <>
      <span style={{ backgroundImage: 'linear-gradient(90deg, #FE6B04 -1.33%, #EA5B04 45.68%)' }}>Clients </span> Testimonial
    </>,
    place: <>Latha African Style BBQ</>,
    person: <>Maria Albegiani - Owner</>,
    review: <>
      We've been working with Kevin [account manager], one-on-one, making sure we do things the right way.
      I can't complain, things are doing better, we are doing better. It's 75% improvement of what we had before.
    </>,
    button: <Link to={'/customer-stories/'} className='more-link'>Explore more stories</Link>,
    image: {
      desktop: (
        <StaticImage
          placeholder="none"
          loading="eager"
          quality="100"
          alt="Latha-African-Style-BBQ"
          src="../images/guest-feedback-monitoring/testimonial.png"
          className="mw-100"
        />
      ),
      mobile: (
        <StaticImage
          placeholder="none"
          loading="eager"
          quality="100"
          alt="Latha-African-Style-BBQ"
          src="../images/guest-feedback-monitoring/testimonial.png"
          className="mw-100"
        />
      ),
    },
  }

  const questions = [
    {
      question: `Why monitor guest feedback?`,
      answer: `N/A`,
    },
    {
      question: `How does guest feedback improve my marketing?`,
      answer: `N/A`,
    },
    {
      question: `How do I respond to feedback?`,
      answer: `N/A`,
    },
    {
      question: `Can I promote positive reviews?`,
      answer: `N/A`,
    },
    {
      question: `How is this different from Google reviews?`,
      answer: `N/A`,
    },
  ];
  const contactBlock = {
    // title: <>Take The <span style={{ backgroundImage: 'linear-gradient(90deg, #FE6B01 27.81%, #B12D01 52.37%)' }}>First Step</span> Towards A More<br /> Powerful Ordering Solution</>,
  }

  return (
    <>
      <Seo
        title={"Local Business Optimization"}
        description={excerpt}
        pageSeo={data.wpPage}
        location={location}
      />
      <Header scrollToForm={scrollToForm}></Header>
      <main>
        <Hero block={heroBlock} />
        <ContentWithImage block={localListings} />
        <Percent block={percent1Block} />
        <Testimonial block={testimonial} />
        <Partners />
        <News />
        <Faq items={questions} />
        <div ref={formRef}>
          <ContactForm block={contactBlock} />
        </div>
      </main>
      <Footer scrollToForm={scrollToForm}></Footer>
    </>
  );
};

export const query = graphql`
  query {
    wpPage(slug: { eq: "guest-feedback-monitoring" }) {
      title
      id
      seo {
        metaKeywords
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        title
        twitterDescription
        twitterTitle
        opengraphSiteName
        opengraphTitle
        opengraphUrl
        focuskw
        opengraphImage {
          localFile {
            url
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        twitterImage {
          localFile {
            url
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        opengraphType
      }
    }
    allWpPost(
      sort: {fields: [date], order: DESC}
      limit: 3
      skip: 0
      filter: {categories: {nodes: {elemMatch: {slug: {eq: "menu-management"}}}}}
    ) {
      nodes {
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
        featuredImage {
          node {
            gatsbyImage(width: 600,quality: 80)
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 90, placeholder: TRACED_SVG, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`;

export default GuestFeedbackMonitoringPage;
